import React from "react";
import { graphql } from "gatsby";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import * as ImageGridStyling from "./ImageGrid.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageGrid = ({ block }) => {
  const { title, images } = block
  return <Section>
    <h2 children={title} />
    <div className="row">
      {images.map((image, i) => <div key={i} className="col c-6 sm-4 m-3">
        <a
          href={image.image.gatsbyImageData.images.fallback?.src}
          download
          aria-label="download"
          children={<GatsbyImage
            className={ImageGridStyling.image}
            image={image.image.gatsbyImageData}
            alt={image.image.alt}
            objectPosition={`${image.image.focalPoint.x * 100}% ${image.image.focalPoint.y * 100}%`}
          />}
        />
        <p
          className="mt-4"
          children={image.image.title}
        />
      </div>)}
    </div>
  </Section>
}

export default ImageGrid

export const query = graphql`
  fragment ImageGrid on DatoCmsImagegrid {
    model {
      apiKey
    }
    id: originalId
    title
    images {
      image {
        title
        alt
        gatsbyImageData(layout: FULL_WIDTH)
        focalPoint {
          x
          y
        }
      }
    }
  }
`;